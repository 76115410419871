
import { Options, Vue } from 'vue-class-component';
import { Prop, ProvideReactive } from 'vue-property-decorator';
import CreditCardPaymentMethod from '@/modules/order/details/components/payment/credit-card-payment.component.vue';
import { orderModule } from '@/modules/order/orderModule.vuex-module';
import ActiveOrder from '@/modules/order/common/classes/ActiveOrder.class';
import { clientModule } from '@/modules/client/client.vuex-module';
import { PaymentMethod } from '@/common/enums/PaymentMethod.enum';
import { OrderPayment } from '@/common/models/OrderPayment.model';
import { PaymentType, ProcessingType } from '@/common/enums/PaymentType.enum';
import { RegisterPayment } from '@/common/models/RegisterPayment.model';
import { createWebHistory } from 'vue-router';
import OrderLanding from './order.view.vue';

@Options({
	name: 'TimePaymentView',
	components: { CreditCardPaymentMethod },
})
export default class TimeWarnView extends Vue {
	canSubmit = false;
	paymentLoading = false;
	error: string | null = null;
	@Prop({ default: '0' }) next!: string;
	@ProvideReactive() get activeOrder(): ActiveOrder | null {
		return orderModule._activeOrder;
	}

	@ProvideReactive() get orderPayment() {
		const payment = this.activeOrder?.paymentDue;
		if (!this.activeOrder?.isNew && payment && !payment.Paid && payment.ProcessingTypeKey != ProcessingType.NOW) {
			console.log('changing processing type key');
			payment.ProcessingTypeKey = ProcessingType.NOW;
		}
		return payment;
	}
	exit(success = false): void {
		const w = window as any;
		if (this.$route.params.orderEntityTimeKey) w.location = `${w.location.origin}/manage/${this.$route.params.clientLocationKey}/${this.$route.params.orderKey}?paid=${success}`;
		else w.location = `${w.location.origin}/manage/${this.$route.params.clientLocationKey}/${this.$route.params.orderKey}?paid=${success}`;
	}
	created(): void {
		console.log('PAYMENT:', this.$route.name);
	}
	get isOrderPayment(): boolean {
		return this.$route.name == 'OrderPayment';
	}
	get paymentAmount() {
		return this.orderPayment?.PaymentAmount;
	}

	get paymentMethod() {
		return this.orderPayment!.PaymentMethodTypeKey;
	}
	get payNow() {
		return this.orderPayment?.ProcessingTypeKey == ProcessingType.NOW;
	}

	async onSubmitPayment() {
		this.error = null;
		if (!this.orderPayment || !this.canSubmit) return;
		let Nonce: string | undefined; // square credit card token
		let OrderPaymentKeyToCharge: string | undefined;
		this.orderPayment.PaymentMethodTypeKey = PaymentMethod.ECOMMERCE;
		var cardValid = true;
		if (orderModule._activeOrder) orderModule._activeOrder.agreed = true;
		if (this.paymentMethod == PaymentMethod.ECOMMERCE) {
			// get token from square SDK
			this.paymentLoading = true;
			const paymentRef = this.$refs.paymentMethod as CreditCardPaymentMethod;
			if (!paymentRef) {
				this.error = 'payment ref is null';
				this.paymentLoading = false;
				return;
			}

			const option = paymentRef.selectedCard;
			if (!option) {
				console.log('Option 0');
				// new card
				await paymentRef?.getSquareToken().then((t) => {
					console.log('NONCE is', t);
					Nonce = t;
					cardValid = !!t;
				});
			} else {
				OrderPaymentKeyToCharge = '' + option;
			}
		} else {
			this.error = 'Payment method is wrong';
			return;
		}
		if (!cardValid) {
			console.log('Card invalid');
			this.paymentLoading = false;
			return;
		}
		this.activeOrder
			?.savePayment({ OrderPaymentKeyToCharge, Nonce })
			.then((res) => {
				var failed = false;
				if (!res) {
					failed = true;
				} else if (res.ModelErrors && res.ModelErrors.length) {
					this.error = res.ModelErrors.map((e: any) => e.Value)
						.flat()
						.join(', ');
				} else if (res.Order) {
					if (res.Order.Balance == 0) this.exit(true);
					else failed = true;
				} else {
					failed = true;
				}
				if (failed) this.error = `Unable to process payment. Please call us at ${clientModule.location?.PublicPhoneNumber}`;
			})
			.finally(() => {
				this.paymentLoading = false;
			});
	}
}
